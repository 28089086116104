import '../css/App.css'
import { useState, useEffect } from 'react'

const App = () => {

  const [dots, setDots] = useState('.')

  useEffect(() => {
    let i = 0
    const interval = setInterval(() => {
      i++
      setDots('.'.repeat(i%4))
    }, 800)
    return (() => {
      clearInterval(interval)
    })
  }, [])

  return (
    <div className='noselect'>
      <div className='soon-wrapper'>
        <h1 className='soon'>Soon{dots}</h1>
        <span className='signature'>~ <a href='https://github.com/0ces'>Øces</a></span>
      </div>
    </div>
  )
}

export default App
